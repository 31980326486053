import React from "react";
import { Fade, Bounce } from "react-awesome-reveal";
import "./Services.css";

function Services() {
  return (
    <div className="services-container" style={{ paddingTop: "150px", paddingBottom: "50px" }}>
      <h1 className="services-title">Our Services</h1>

      <Fade triggerOnce>
        <section className="service-section">
          <Bounce cascade triggerOnce>
            <h2 className="service-heading">AI Research</h2>
          </Bounce>
          <p className="service-description">
            At SoloGen AI, we push the boundaries of artificial intelligence with innovative research.
            Our team explores the latest advancements in machine learning, computer vision, natural language processing,
            and more. We are committed to building AI systems that are not only powerful but also ethical and beneficial to society.
          </p>
        </section>
      </Fade>

      <Fade triggerOnce>
        <section className="service-section">
          <Bounce cascade triggerOnce>
            <h2 className="service-heading">Custom AI Solutions</h2>
          </Bounce>
          <p className="service-description">
            SoloGen AI provides custom-tailored AI solutions for businesses across various industries.
            Whether it's predictive analytics, intelligent automation, or advanced data processing,
            we help you harness the power of AI to drive efficiency and innovation.
          </p>
        </section>
      </Fade>

      <Fade triggerOnce>
        <section className="service-section">
          <Bounce cascade triggerOnce>
            <h2 className="service-heading">Consulting & Training</h2>
          </Bounce>
          <p className="service-description">
            Our consulting services are designed to help organizations integrate AI effectively.
            From strategy development to technical implementation, we guide you at every step of your AI journey.
            We also provide training sessions to upskill your teams, enabling them to leverage AI tools and techniques.
          </p>
        </section>
      </Fade>
    </div>
  );
}

export default Services;
