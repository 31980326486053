import React from "react";
import "./Jobs.css";

function Jobs() {
  return (
    <div className="jobs-container" style={{ paddingTop: "150px", paddingBottom: "50px" }}>
      <h1 className="jobs-title">Join Our Team</h1>
      <p className="jobs-description">
        SoloGen AI is looking for talented and enthusiastic trainees to join our team!
      </p>
      <section className="job-role">
        <h2>Bachelor's Trainees</h2>
        <p>
          We welcome bachelor's students who are eager to dive into the world of artificial intelligence.
          Gain hands-on experience and work alongside our team of experts. If you’re passionate about AI research
          and development, we'd love to hear from you!
        </p>
      </section>
      <section className="job-role">
        <h2>Master's Trainees</h2>
        <p>
          Master's students are invited to work on advanced projects in AI and contribute to groundbreaking research
          and solutions. This role is ideal for those looking to deepen their expertise and make a meaningful impact.
        </p>
      </section>
      <p className="application-info">
        Interested candidates can send their CVs to <a href="mailto:hr@sologen-ai.com">hr@sologen-ai.com</a>.
      </p>
    </div>
  );
}

export default Jobs;
